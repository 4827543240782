/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var isMobile = {
              Android: function() {
                return navigator.userAgent.match(/Android/i);
              },
              BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
              },
              iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
              },
              Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
              },
              Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
              },
              any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
              }
          };

        // Parallax
        var parallax = function() {
            $(window).stellar();
        };

        // Mobile outside click close offcanvas
        var mobileMenuOutsideClick = function() {

              $(document).click(function (e) {
              var container = $("#offcanvas, .js-nav-toggle");
              if (!container.is(e.target) && container.has(e.target).length === 0) {

                  if ( $('body').hasClass('offcanvas') ) {

                      $('body').removeClass('offcanvas');
                      $('.js-nav-toggle').removeClass('active');
                  }
              }
              });

          };

       // Offcanvas menu
       var offcanvasMenu = function() {

            $('#page').prepend('<a href="#" class="js-nav-toggle nav-toggle"><i></i></a>');


            $('#offcanvas .menu-item-has-children').addClass('offcanvas-has-dropdown');
            $('#offcanvas')
                .find('li')
                .removeClass('menu-item-has-children');

            // Hover dropdown menu on mobile
            $('.offcanvas-has-dropdown').mouseenter(function(){

              $(this)
                    .addClass('active')
                    .find('ul')
                    .slideDown(500, 'easeOutExpo');
            }).mouseleave(function(){

                $(this)
                    .removeClass('active')
                    .find('ul')
                    .slideUp(500, 'easeOutExpo');
            });


            $(window).resize(function(){

                if ( $('body').hasClass('offcanvas') ) {

                    $('body').removeClass('offcanvas');
                    $('.js-nav-toggle').removeClass('active');

                }
            });
        };

        var burgerMenu = function() {

            $('body').on('click', '.js-nav-toggle', function(event){
                var $this = $(this);


                if ( $('body').hasClass('offcanvas') ) {
                    $('body').removeClass('offcanvas');
                } else {
                    $('body').addClass('offcanvas');
                }
                $this.toggleClass('active');
                event.preventDefault();

            });
        };

        var goToTop = function() {

            $('.js-gotop').on('click', function(event){

                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $('html').offset().top
                }, 500);

                return false;
            });

        };

         // Loading page
        var loaderPage = function() {
            $(".loader").fadeOut("slow");
        };

        // Window Scroll
        var windowScroll = function() {
            var lastScrollTop = 0;

            $(window).scroll(function(event){

                var header = $('#header'),
                    scrlTop = $(this).scrollTop();

                if ( scrlTop > 500 && scrlTop <= 2000 ) {
                    header.addClass('navbar-fixed-top animated slideInDown');
                } else if ( scrlTop <= 500) {
                    if ( header.hasClass('navbar-fixed-top') ) {
                        header.addClass('navbar-fixed-top animated slideOutUp');
                        setTimeout(function(){
                            header.removeClass('navbar-fixed-top animated slideInDown slideOutUp');
                        }, 100 );
                    }
                }

            });
        };

        // Animate content on scroll
        var contentWayPoint = function() {
          var i = 0;
          $('.animate-box').waypoint( function( direction ) {

              if( direction === 'down' && !$(this.element).hasClass('animated') ) {

                  i++;

                  $(this.element).addClass('item-animate');
                  setTimeout(function(){

                      $('body .animate-box.item-animate').each(function(k){
                          var el = $(this);
                          setTimeout( function () {
                              var effect = el.data('animate-effect');
                              if ( effect === 'fadeIn') {
                                  el.addClass('fadeIn animated');
                              } else if ( effect === 'fadeInLeft') {
                                  el.addClass('fadeInLeft animated');
                              } else if ( effect === 'fadeInRight') {
                                  el.addClass('fadeInRight animated');
                              } else {
                                  el.addClass('fadeInUp animated');
                              }

                              el.removeClass('item-animate');
                          },  k * 200, 'easeInOutExpo' );
                      });

                  }, 100);

              }

          } , { offset: '85%' } );
      };

      var initFlexSlider = function() {
        $('#carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 100,
          itemMargin: 5,
          asNavFor: '#slider'
        });

        var $slider = $('#slider');

        $slider.flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: true,
          slideshow: false,
          sync: "#carousel"
        });

        $slider.find('.flex-next, .flex-prev').click(function(e) {
            e.stopPropagation();
        });
      };


      var initMasonry = function() {
        var $grid = $('.masonry-grid').masonry({
            itemSelector: '.masonry-column',
            columnWidth: '.masonry-column-sizer',
            percentPosition: true
          });

        $grid.imagesLoaded().progress( function() {
          $grid.masonry('layout');
        });

        $('a[data-toggle=tab]').each(function () {
          var $this = $(this);

          $this.on('shown.bs.tab', function () {
           $grid.imagesLoaded().progress( function() {
              $grid.masonry('layout');
            });
          });
        });

      };

      var initMagnificPopup = function() {
        $('#slider').each(function() { // the containers for all your galleries
            var $self = $(this);
            var isPopupOpened = false;

            $self.magnificPopup({
                delegate: 'li:not(.clone) a.slides-anchor', // the selector for gallery item (ignore flexslider clone images)
                type: 'image',
                gallery: {
                  enabled:true,
                  arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><i class="ion-chevron-%dir%"></i></button>'
                },
                callbacks: {
                    open: function() {
                        var $leftArrow = $(this.arrowLeft);
                        var $rightArrow = $(this.arrowRight);
                        // Prevent the click to bubble up to the mfp bg, which would close the popup
                        $leftArrow.add($rightArrow).click(function(e) {
                            e.stopPropagation();
                        });

                        var carouselCurrentIndex = $self.data('flexslider').currentSlide % 5;
                        $self.magnificPopup('goTo', carouselCurrentIndex);

                        isPopupOpened = true;
                    },
                    change: function() {
                        if (isPopupOpened) {
                            var currentIndex = this.currItem.index;
                            $self.flexslider(currentIndex);
                        }
                    },
                    close: function() {
                        var currentIndex = this.currItem.index;
                        $self.flexslider(currentIndex);

                        isPopupOpened = false;
                    },
                },
            });
        });

        $('.masonry-grid').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item (ignore flexslider clone images)
                type: 'image',
                gallery: {
                    enabled:true,
                    arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><i class="ion-chevron-%dir%"></i></button>'
                },
                callbacks: {
                    open: function () {
                        var $leftArrow = $(this.arrowLeft);
                        var $rightArrow = $(this.arrowRight);
                        // Prevent the click to bubble up to the mfp bg, which would close the popup
                        $leftArrow.add($rightArrow).click(function (e) {
                            e.stopPropagation();
                        });
                    },
                },
            });
        });

      };

      var selectToJs = function() {
        $('select').selectize();
      };

      var handleAjaxLogin = function() {
        $('#loginform').submit(function(e) {
          e.preventDefault();

          var $form = $(this);
          var $formGroups = $form.find('.form-group');
          var $loginInput = $form.find('#user-login');
          var $passInput = $form.find('#user-pass');

          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login.admin_ajax,
            data: {
              'action': 'participant_ajax_login',
              'username': $loginInput.val(),
              'password': $passInput.val(),
              'rememberme': $form.find('#rememberme').is(':checked'),
              'security': $form.find('#security').val()
            },
            success: function(response) {
              if(response.data.redirect) {
                window.location.href = response.data.redirect;
              } else {
                window.location.reload();
              }
            },
            error: function() {
              Sage.common.addValidationError($loginInput, $form);
              Sage.common.addValidationError($passInput, $form, '', true);
            },
          });
        });

        $('#signupform').submit(function(e) {
          e.preventDefault();
          var $form = $(this);
          var $password = $form.find('#signup-user-pass');
          var $passwordConfirm = $form.find('#signup-user-pass-confirm');

          // @todo Get localised error messages to display them using JS
          // if($.trim($password.val()) !== $.trim($passwordConfirm.val())) {
          //   Sage.common.addValidationError($password, $form, '', true);
          //   Sage.common.addValidationError($passwordConfirm, $form, '', true);
          //
          //   return;
          // }

          var data = $(this).serializeArray();
          data.push({ name: 'action', value: 'register_participant_user' });

          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_login.admin_ajax,
            data: data,
            success: function(response) {
              if(response.data.redirect) {
                window.location.href = response.data.redirect;
              } else {
                window.location.reload();
              }
            },
            error: function(jqXHR) {
              var response = {};

              try {
                response = JSON.parse(jqXHR.responseText);
              } catch(e) {
                response = jqXHR.responseText;
              }

              if(response.data.errors) {
                $.each(response.data.errors, function(i, e) {
                  $.each(e.errors, function(key, error) {
                    var selector = '';
                    var clearValue = false;

                    if(key.indexOf('user_pass_confirm') >= 0) {
                      selector = 'user_pass_confirm';
                      clearValue = true;
                    } else if(key.indexOf('user_pass') >= 0) {
                      selector = 'user_pass';
                      clearValue = true;
                    } else if(key.indexOf('user_login') >= 0) {
                      selector = 'user_login';
                    } else if(key.indexOf('user_email') >= 0) {
                      selector = 'user_email';
                    } else if(key.indexOf('first_name') >= 0) {
                      selector = 'first_name';
                    } else if(key.indexOf('last_name') >= 0) {
                      selector = 'last_name';
                    }

                    if(selector) {
                      Sage.common.addValidationError($form.find('input[name=' + selector + ']'), $form, error[0], clearValue);
                    }
                  });
                });
              }
            },
          });
        });

        $('#lostpasswordform').submit(function(e) {
            e.preventDefault();

            var $form = $(this);
            var data = $(this).serializeArray();
            data.push({ name: 'action', value: 'participant_ajax_lostpassword' });

            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: ajax_login.admin_ajax,
                data: data,
                success: function(response) {
                    if (response.data.message) {
                        $form.siblings('.alert').removeClass().addClass('alert alert-success').text(response.data.message);
                    }
                },
                error: function(jqXHR) {
                    var response = {};

                    try {
                        response = JSON.parse(jqXHR.responseText);
                    } catch(e) {
                        response = jqXHR.responseText;
                    }

                    if(response.data.message) {
                        $form.siblings('.alert').removeClass().addClass('alert alert-danger').html(response.data.message);
                    }
                },
            });
        });
      };


      // Document on load.
      $(function(){
          parallax();
          mobileMenuOutsideClick();
          offcanvasMenu();
          burgerMenu();
          contentWayPoint();
          windowScroll();
          goToTop();
          initFlexSlider();
          initMasonry();
          initMagnificPopup();
          selectToJs();
          loaderPage();
          handleAjaxLogin();
      });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      addValidationError: function($input, $form, message, clearValue) {
        var $parentFormGroup = $input.parent('.form-group');
        var $message = null;
        var cleanUp = function() {
          $parentFormGroup.removeClass('has-error');

          if($message) {
            $message.remove();
          }
        };

        if(message) {
          $message = $('<small class="help-block"></small>').text(message);
        }

        if(clearValue) {
          $input.val('');
        }

        $input.one('input', function() {
          cleanUp();
          $form.off('submit', cleanUp);
        });

        $form.one('submit', function() {
          cleanUp();
          $input.off('input', cleanUp);
        });

        $parentFormGroup.addClass('has-error').append($message);
      },
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_cruise': {
      init: function() {
        var validNumericInput = function($input) {
          var value = parseInt($input.val());
          var max = parseInt($input.attr('max'));
          var min = parseInt($input.attr('min'));

          return min <= value && value <= max;
        };

        var $bookingForm = $('#booking-form');
        var $bookingTotalPrice = $bookingForm.find('#booking-total-price');
        var $nbBookedPlaceInput = $bookingForm.find('#nb-booked-places');
        var $bookingGuestTabPane = $bookingForm.find('#booking-guest-1');
        var pricePerPlace = parseFloat($bookingForm.data('price-per-place'));
        var currency = '';
        var currencyMatch = $bookingTotalPrice.text().match(/^\s*(\w{3})/);

        if(currencyMatch) {
          currency = currencyMatch[1];
        }

        $bookingGuestTabPane.remove();

        $('#open-cruise-booking').magnificPopup({ type: 'inline' });

        $nbBookedPlaceInput.change(function() {
          if(validNumericInput($nbBookedPlaceInput)) {
            var nbBookedPlace = parseInt($nbBookedPlaceInput.val());
            var totalPrice = nbBookedPlace * pricePerPlace;
            var formattedPrice = totalPrice.toFixed(2);

            formattedPrice = formattedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
            formattedPrice = currency + ' ' + formattedPrice.replace(/\.00/, '.-');

            $bookingTotalPrice.text(formattedPrice);
          }
        });

        $bookingForm.keydown(function (e) {
          // Prevent form submit on Enter key
          if ((e.key && e.key === 'Enter') || (e.keyCode && e.keyCode === 13)) {
            e.preventDefault();
          }
        });

        var $bookingParticipant = $bookingForm.find('#booking-participant');
        var $participantIsEquipped = $bookingParticipant.find('input[name="participant_is_equipped[]"]');
        var $participantRent = $bookingParticipant.find('[name="participant_rent[]"]');

        $bookingParticipant.find('input.date-validation').on('keyup', function (e) {
          if ((e.key && e.key === 'Backspace') || (e.keyCode && e.keyCode === 8)) {
            return;
          }

          var $this = $(this);
          var value = $this.val();

          if ((value.length === 2 && moment(value, 'DD', true).isValid()) || value === '31') {
            $this.val($this.val() + '/');
          } else if (value.length === 5 && moment(value, 'DD/MM', true).isValid()) {
            $this.val($this.val() + '/');
          }
        });

        if ($participantIsEquipped.is(':checked')) {
          $participantRent.hide();
        }

        $participantIsEquipped.change(function (e) {
          if ($participantIsEquipped.is(':checked')) {
            $participantRent.hide();
          } else {
            $participantRent.show();
          }
        });

        var handleBookingProcessPanels = function (e) {
          var $btn = $(this);
          var isNext = $btn.hasClass('btn-next');
          var $parentTabPane = $btn.closest('.tab-pane');
          var parentTabPaneId = $parentTabPane.attr('id');
          var nbBookedPlaces = parseInt($nbBookedPlaceInput.val());

          if (isNext && parentTabPaneId === 'booking-participant') {
            var $requiredInputs = $parentTabPane.find('input[required]');
            var $notFilledRequiredInputs = $();
            var $dateInput = $parentTabPane.find('input.date-validation');

            $parentTabPane.find('.alert').remove();
            $parentTabPane.find('.form-group.has-error').each(function (i, e) {
              $(this).removeClass('has-error');
            });

            $dateInput.each(function (i, e) {
              var $this = $(this);

              if (!moment($this.val(), 'DD/MM/YYYY', true).isValid()) {
                $this.closest('.form-group').addClass('has-error');
                $('<div class="alert alert-danger" role="alert">Format de date non valide.</div>').insertBefore($parentTabPane.find('.btn-prev'));
              }
            });

            $requiredInputs.each(function (i, e) {
              var $input = $(this);

              if ($input.attr('type') !== 'radio') {
                if (!$input.val()) {
                  $notFilledRequiredInputs = $notFilledRequiredInputs.add($input);
                }
              } else {
                var name = $input.attr('name');
                var $otherRadios = $parentTabPane.find('input[name="' + name + '"]:checked');

                if ($otherRadios.length === 0) {
                  $notFilledRequiredInputs = $notFilledRequiredInputs.add($input);
                }
              }
            });

            if ($notFilledRequiredInputs.length > 0) {
              $notFilledRequiredInputs.each(function (i, e) {
                $(this).closest('.form-group').addClass('has-error');
              });

              $('<div class="alert alert-danger" role="alert">Veuillez remplir les champs obligatoires.</div>').insertBefore($parentTabPane.find('.btn-prev'));

              return false;
            }
          }

          var $firstNameInputs = $bookingForm.find('[name="participant_first_name[]"]');
          var $lastNameInputs = $bookingForm.find('[name="participant_last_name[]"]');

          var names = $firstNameInputs.map(function (i, e) {
            return $(this).val();
          });

          names = $.map(names, function (name, i) {
            var lastName = $lastNameInputs.eq(i).val();

            if (name || lastName) {
              name = name + ' ' + lastName;
            }

            if (typeof name.trim === 'function') {
              return name.trim();
            }

            return name;
          });

          $bookingForm.find('#booking-summary-nb-places').text(1);
          $bookingForm.find('#booking-summary-total-price').text($bookingTotalPrice.text());
          $bookingForm.find('#booking-summary-participant-names').text(names.join(', '));

          var $tabPaneToTrigger = isNext ? $parentTabPane.next('.tab-pane') : $parentTabPane.prev('.tab-pane');
          $parentTabPane.removeClass('in active');
          $tabPaneToTrigger.addClass('in active');
        };

        $bookingForm.find('.btn-prev, .btn-next').click(handleBookingProcessPanels);
      },
    },
    'page_template_template_my_account': {
      init: function() {
        if($('#has-no-participant-role').length > 0) {
          $('#login-modal').modal('show');
        } else {
          // Opens automatically the tab specified in the hash of the URL
          if (location.hash) {
            $('a[href="' + location.hash + '"]').tab('show');
          }

          $(document.body).on('click', 'a[data-toggle=tab]', function (event) {
            location.hash = $(this).attr('href');
          });

          // @todo Refactor callback events below
          $('#mailchange-form').submit(function(e) {
            e.preventDefault();

            var $form = $(this);
            var $passInput = $form.find('#user-pass-auth');
            var $emailConfInput = $form.find('#user-email-conf');
            var data = $form.serializeArray();
            data.push({ name: 'action', value: 'participant_email_change' });

            $.ajax({
              type: 'POST',
              dataType: 'json',
              url: ajax_login.admin_ajax,
              data: data,
              success: function() {
                window.location.reload();
              },
              error: function(jqXHR) {
                var response = {};

                try {
                  response = JSON.parse(jqXHR.responseText);
                } catch(e) {
                  response = jqXHR.responseText;
                }

                $passInput.val('');

                if(response.data.errors) {
                  $.each(response.data.errors, function(i, e) {
                    $.each(e.errors, function(key, error) {
                      var selector = '';
                      var clearValue = false;

                      if(key.indexOf('user_pass') >= 0) {
                        selector = 'user_pass';
                        clearValue = true;
                      } else if(key.indexOf('user_email_conf') >= 0) {
                        selector = 'user_email_conf';
                        clearValue = true;
                      } else if(key.indexOf('user_email') >= 0) {
                        selector = 'user_email';
                        $emailConfInput.val('');
                      }

                      if(selector) {
                        Sage.common.addValidationError($form.find('input[name=' + selector + ']'), $form, error[0], clearValue);
                      }
                    });
                  });
                }
              },
            });
          });

          $('#passchange-form').submit(function(e) {
            e.preventDefault();

            var $form = $(this);
            var $passInputs = $form.find('input[type=password]');
            var data = $form.serializeArray();
            data.push({ name: 'action', value: 'participant_password_change' });

            $.ajax({
              type: 'POST',
              dataType: 'json',
              url: ajax_login.admin_ajax,
              data: data,
              success: function() {
                window.location.reload();
              },
              error: function(jqXHR) {
                var response = {};

                try {
                  response = JSON.parse(jqXHR.responseText);
                } catch(e) {
                  response = jqXHR.responseText;
                }

                $passInputs.val('');

                if(response.data.errors) {
                  $.each(response.data.errors, function(i, e) {
                    $.each(e.errors, function(key, error) {
                      var selector = '';
                      var clearValue = false;

                      if(key.indexOf('user_pass_old') >= 0) {
                        selector = 'user_pass_old';
                      } else if(key.indexOf('user_pass_conf') >= 0) {
                        selector = 'user_pass_conf';
                      } else if(key.indexOf('user_pass') >= 0) {
                        selector = 'user_pass';
                      }

                      if(selector) {
                        Sage.common.addValidationError($form.find('input[name=' + selector + ']'), $form, error[0], clearValue);
                      }
                    });
                  });
                }
              },
            });
          });
        }
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
